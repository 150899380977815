import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import * as styles from "../assets/css/products.module.scss"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Ideas from "../components/ideas/ideas"

import { Strings } from "../constants/strings"
import { fetchProducts, selectProducts } from "../redux/slices/productsSlice"

const ProductsPage = () => {
  const products = useSelector(selectProducts)
  const dispatch = useDispatch()

  const loadData = React.useCallback(async () => {
    try {
      if (products.loading === "idle") {
        dispatch(fetchProducts())
      }
    } catch (e) {
      console.log(e)
    }
  }, [dispatch, products])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  console.log(products.products)
  return (
    <Layout>
      <Seo title={`Home - ${Strings.companyName}`} />
      <h2>{Strings.products["en"]}</h2>
      <div className={styles.container}>
        {products.products.map(elem => (
          <div key={elem.id} className={styles.product}>
            <img
              src={elem.attributes.image.data?.attributes?.url}
              alt={`product_${elem.attributes.title}`}
            />
            <div>
              <h3>{elem.attributes.title}</h3>
            </div>
          </div>
        ))}
      </div>
      <Ideas form={true} title={Strings.haveAProjectInMind["en"]} />
    </Layout>
  )
}

export default ProductsPage
